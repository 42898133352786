import { Environment } from './environment.type';

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
export const environment: Environment = {
  production: false,
  legacyAuth0PatientDatabaseConnection: 'PatientApp-Username-Password',
  newAuth0PatientDatabaseConnection: 'Patient-Authentication',
  legacyAuth0DomainForBuild: 'cb-dev.auth0.com',
  newAuth0DomainForBuild: 'nice-healthcare-staging.us.auth0.com',
  newAuth0CustomDomainForBuild: 'login.staging.noice.healthcare',
  apiUrl: 'https://nice-cb-dev.uc.r.appspot.com/api/v1',
  apiV2Url: 'https://nice-cb-dev.uc.r.appspot.com/api/v2',
  apiV3Url: 'https://nice-cb-dev.uc.r.appspot.com/api/v3',
  niceServiceUrl: 'https://vanity-api.noice.healthcare/api',
  returnToUrl: 'https://patient.legacy.staging.noice.healthcare',
  webUrl: 'https://patient.legacy.staging.noice.healthcare',
  newWebUrl: 'https://patient.web.staging.noice.healthcare',
  chatPollingRate: 1000,
  chatPollingRateBackground: 10000,
  amplitudeApiKey: 'e6b01bd7b1011ac4b5748d7fc32a3167',
  contentfulConfig: {
    spaceId: 'wrx4fksf2sw5',
    deliveryAccessToken: '1b3987c11c07af8c1d533092a204dd1dce982f066b8b38ee8cab2adca93f2d47',
    environment: 'staging',
    contentTypes: {
      announcement: 'announcement',
    },
  },
  squareApplicationId: 'sandbox-sq0idb-GhKDvNVd6cyQAusGAbCjfQ',
  squareLocationId: 'W7PZ562QE41YY',
  sentryConfig: {
    environmentName: 'staging',
    dsn: 'https://195ba766089147e098e337d5625f13b6@o354533.ingest.sentry.io/5716593',
    sampleRate: 1.0,
  },
  featuresEnabled: {
    documents: true,
  },
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
